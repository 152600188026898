import React, {Component} from 'react';
import Helmet from 'react-helmet';
import Layout from '../../../components/layout';
// import './oauth-confirmation.scss';

class OAuthConfirmationPage extends Component {
  render() {
    return (
      <Layout>
        <div className="oauth-confirmation-container">
          <Helmet title={`Datasert - Realfire OAuth Confirmation`} />

          <div className="content">
            <h2>Datasert Realfire</h2>
            <h3>Salesforce OAuth Authentication Completed</h3>
            <h4>You may close this page and return to Realfire App</h4>
            <hr />
            <div>
              <h2>
                <a href="/products/realfire/release-notes">Realfire Version 5.2</a>
              </h2>
              <ul>
                <li>
                  Apex Looper <sup>New</sup>
                </li>
                <li>
                  Async Soqls <sup>New</sup>
                </li>
                <li>
                  Schema Report <sup>New</sup>
                </li>
                <li>
                  Excel/Gsheet support <sup>New</sup>
                </li>
                <li>
                  Parent Id Lookup <sup>New</sup>
                </li>
                <li>and much more...</li>
              </ul>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default OAuthConfirmationPage;
